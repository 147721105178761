import React from 'react';
import styled from 'styled-components';
import { omit } from 'lodash';
import Button from '../../Common/Button';

export const Body = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px 50px 0;
  overflow: auto;
  background-color: rgb(251, 251, 252);
  border-color: rgb(223, 223, 223);
  border-style: solid none solid solid;
  border-width: 1px medium 1px 1px;
  border-image: none 100% / 1 / 0 stretch;
  border-radius: 6px 0px 0px 6px;
`;

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    min-height: 80%;
    width: 85%;
`;

export const SettingsPanel = styled.div( ({transparent, placeholder}) => `
    width: 100%;
    padding: 20px;
    ${!transparent ? `
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 3px 5px 0px;
        border-radius: 4px;
    ` : !placeholder ? `flex-grow: inherit;` : `` 
    }
    display: flex;
    flex-direction: row;
`);

export const SettingsPanelColumn = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const SettingsPanelItem = styled.div(({type}) => `
    padding-top: 2%;
    padding-bottom: ${type === 'text' ? 0 : 6}%;
    padding-right: 5%;
    margin: 0 auto;
    width: 80%;
    ${type === 'submit' ?  `padding-bottom: 12%;` : `` }
`);

export const SaveSettingsContainer = styled.div`
    display: flex;
`;

const ButtonBase = props => <Button {...omit(props, ['redButtons'])} />;

export const ApplyButton = styled(ButtonBase)`
  font-weight: 500;
  font-size: .95625rem;
  min-width: 145px;
  height: 37px;
  border-radius: 4px;
  background-color: #23a0fe;
  box-shadow: 0px 15px 29px 0 rgba(51, 70, 85, 0.24);
  padding: 0 10px;
  margin-left: 2.8%;
  float: right;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  color: #ffffff;

  &:disabled {
    opacity: 0.3;
    color: rgba(51, 51, 51, 0.7);
  }

  transition: 0.25s;

  &:hover {
    background-color: #1078f0;
    box-shadow: 0px 10px 29px 0 rgba(51, 70, 85, 0.4);
  }
  &:active {
    background-color: #0472ed;
    box-shadow: 0px 0px 20px 0 rgba(51, 70, 85, 0.5);
  }

`;
