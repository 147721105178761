/* eslint-disable */
import styled from 'styled-components';

const Header = styled.div`
  background-color: #d9d9db;
  font-family: Montserrat;
  font-size: 16px;
  line-height: 53px;
  text-align: center;
  color: #333333;
`;

export default Header;
