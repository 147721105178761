import { Redirect } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import i18n from "../../i18n";
import Nav from "../Nav";
import { Body, Content, } from "./styled";
import { H1 } from "../../Common/Header";
import { useHistory } from "react-router-dom";
import Form from './form'
import * as Yup from "yup";
import { Formik } from "formik";
import {
    useParams,
    Link
} from "react-router-dom";
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import stopsContainer from '../../contexts/stopsContainer';

const FieldIsRequiredMsg = i18n.t('settings.validation.fieldIsRequired');

export default () => {
    const stops = stopsContainer.useContainer();
    const [displayLoader, setDisplayLoader] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const history = useHistory();
    const [position, setPosition] = useState([0, 0]);

    let { id } = useParams();

    if (!localStorage.token) {
        return <Redirect to="/login" />;
    }

    useEffect(() => {
        stops.loadSelectedStop(id);
    }, []);

    useEffect(() => {
        if (stops.selectedStop) {
            setShowForm(true);
            if (stops.selectedStop.latitude) {
                setPosition([stops.selectedStop.latitude, stops.selectedStop.longitude]);
            }
        }
    }, [stops.selectedStop]);



    return (
        <Fragment>
            <Body>
                <Nav />
                <Content>
                    <H1>
                        {id === 'new' ? "New MoD Stop" : "Edit MoD Stop"}
                    </H1>

                    <div class="row">
                        <div class="col-7">
                            <Link to="/stops" className="btn btn-primary">Back</Link>
                            {id !== 'new' ?
                                <div>
                                    <br />
                                    <button
                                        className="btn btn-danger"
                                        onClick={async () => {
                                            const ok = window.confirm("delete?");
                                            if (ok) {
                                                await stops.removeStop(id);
                                                history.push('/stops');
                                            }
                                        }
                                        }
                                    >Delete MoD Stop</button>
                                    <Map center={position} zoom={18} style={{ width: '100%', height: '480px', margin: '25px' }}>
                                        <TileLayer
                                            attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                        />
                                        <Marker position={position}>
                                        </Marker>
                                    </Map>
                                    <p>Base Price: {stops.selectedStop.basePrice} € | Price per km : {stops.selectedStop.kilometerPrice} € | Airline Distance Price per km: {stops.selectedStop.airlineDistancePrice} €</p>

                                    <table className="table" style={{ width: '100%' }}>
                                        <tr>
                                            <th scope="col">MoDStop End</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Price</th>
                                            <th scope="col">Surcharge</th>
                                            <th scope="col">Distance</th>
                                            <th scope="col">Airline Distance</th>
                                        </tr>
                                        <tbody>
                                            {stops.selectedStop.prices ? stops.selectedStop.prices.map(e => <tr key={e.id}>
                                                <td scope="row">{e.modstop_end_id}</td>
                                                <td>{e.modstop_end_name}</td>
                                                <td>{(e.estimatedPrice / 100).toFixed(2)} €</td>
                                                <td>{(e.surcharge / 100).toFixed(2)} €</td>
                                                <td>{(e.distance / 1000).toFixed(1)} km</td>
                                                <td>{(e.airlineDistance / 1000).toFixed(1)} km</td>
                                            </tr>) : null}
                                        </tbody>
                                    </table>
                                </div>
                                : null}
                        </div>
                        <div class="col-5">
                            {showForm && ( id === 'new' || stops.selectedStop.id === id )&&
                                <Formik
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    initialValues={id === 'new' ? {} : stops.selectedStop }
                                    validationSchema={Yup.object().shape({
                                        id: Yup.string().required(FieldIsRequiredMsg),
                                        name: Yup.string().required(FieldIsRequiredMsg),
                                        latitude: Yup.number().required(FieldIsRequiredMsg),
                                        longitude: Yup.number().required(FieldIsRequiredMsg),
                                        area: Yup.string(),
                                        type: Yup.string(),
                                        address: Yup.string().required(FieldIsRequiredMsg),
                                        imageUrl: Yup.string(),
                                        phase: Yup.string().required(FieldIsRequiredMsg),
                                    })}
                                    onSubmit={async (values, actions) => {
                                        actions.setSubmitting(true);
                                        setTimeout(() => setDisplayLoader(true), 100);

                                        try {

                                            if (id === "new") {
                                                // create
                                                await stops.createStop(values);
                                            } else {
                                                // save
                                                await stops.saveStop(id, values);
                                            }
                                            alert("success");
                                            history.push('/stops');
                                        } catch (error) {
                                            console.log(error);
                                            alert(error);
                                        }
                                        actions.setSubmitting(false);
                                        setTimeout(() => setDisplayLoader(false), 1200);
                                    }}
                                    render={props => (
                                        <Form {...props} displayLoader={displayLoader} />
                                    )}
                                />}
                        </div>

                    </div>


                </Content>
            </Body>
        </Fragment>
    );
};
