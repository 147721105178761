import React, {Fragment, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import i18n from '../../i18n';
import settingsContainer from '../../contexts/settingsContainer';
import Nav from '../Nav';
import {H1} from '../../Common/Header';
import Table from '../../Common/Table/themes/strips';
import Toggle from '../../Common/Toggle';
import Popup from '../../Common/Popup';
import {generateAvatarById} from '../../Services/avatar';
import {
    Body,
    Content,
    Buttons,
    Avatar,
    avatarSize,
    SvgBase
} from './styled';
import usersContainer from '../../contexts/usersContainer';
import {ReactComponent as deleteIcon} from '../../assets/delete.svg';
import {ReactComponent as editIcon} from '../../assets/edit.svg';
import SvgIcon from '../../Common/SvgIcon';
import networkService from '../../Services/network';

const customAvatarSeed = 'Auto Fleet';

const sendmail = async (name, mail) => {
    // sends mail to the user when account gets activated
    try {
        await networkService.post('/api/v1/mail', {name, mail});
    } catch (e) {
        console.log(e);
    }
};


const makeColumns = (free) => [
    {
        Header: '',
        width: 60,
        id: 'avatar',
        Cell: ({original: user}) => {
            if (user.avatar) {
                return <Avatar src={user.avatar}/>;
            }
            return (<SvgBase
                svg={generateAvatarById(`${user.firstName} ${user.lastName}` || customAvatarSeed)}
                width={avatarSize}
                height={avatarSize}
            />);
        },
    },
    {accessor: 'firstName', Header: i18n.t('users.firstName')},
    {accessor: 'lastName', Header: i18n.t('users.lastName')},
    {accessor: 'email', Header: i18n.t('users.email')},
    {accessor: 'phoneNumber', Header: i18n.t('users.phoneNumber')},
    {accessor: 'code', Header: i18n.t('users.transferwiseCode')},
    /*
    {
        accessor: 'active',
        Header: i18n.t('users.status'),
        Cell: ({value}) => (value ? i18n.t('users.active') : i18n.t('users.disabled')),
    },
     */
    {
        accessor: 'balance',
        Header: i18n.t('users.balance'),
        Cell: ({value}) => `${(value / 100).toFixed(2).replace('.', ',')} €`,
    },
    {
        accessor: 'freeRide',
        Header: i18n.t('users.freeRide'),
        Cell: ({value}) => (free ? i18n.t('users.allFree') : (value.freeRide ? i18n.t('users.free') : i18n.t('users.payed'))),
    }
];

export default () => {
    if (!localStorage.token) {
        return <Redirect to="/login"/>;
    }

    const settings = settingsContainer.useContainer();
    const users = usersContainer.useContainer();

    const [popupState, setPopupState] = useState('');
    const [chosenUser, setChosenUser] = useState({});
    const [freeRideGen, setFreeRideGen] = useState(false);

    useEffect(() => {
        settings.loadSettings();
    }, []);

    useEffect(() => {
        if (settings.settingsList) {
            setFreeRideGen(settings.settingsList.FREE_RIDE);
        }
    }, [settings.settingsList]);

    const columns = [...makeColumns(freeRideGen),
        {
            Header: 'Free Ride',
            id: 'freeRide',
            minWidth: 50,
            accessor: ({id, freeRide}) => ({id, freeRide}),
            Cell: ({value: {id, freeRide}}) => ( // eslint-disable-line react/prop-types
                <section>
                    <Toggle
                        value={`${freeRide}`}
                        checked={freeRide === true}
                        onChange={async (event) => {
                            if (event.target.checked) {
                                await users.UpdateUser(id, {freeRide: true});
                            } else {
                                await users.UpdateUser(id, {freeRide: false});
                            }
                        }}
                    />
                </section>
            ),
        },
        {
            Header: '',
            id: 'buttons',
            minWidth: 90,
            accessor: ({id, active}) => ({id, active}),
            Cell: ({value: {id, active}}) => ( // eslint-disable-line react/prop-types
                <Buttons>
                    <SvgIcon
                        svg={editIcon}
                        onClick={() => {
                            const userData = users.getUser(id);
                            setChosenUser(userData);
                            setPopupState('EditUser');
                        }}
                    />
                    <SvgIcon
                        svg={deleteIcon}
                        disableClass={active}
                        onClick={() => {
                            if (!active) {
                                users.deleteUser(id);
                            }
                        }}
                    />
                </Buttons>
            ),
        },
        {
            Header: 'Aktiv',
            id: 'toggle',
            minWidth: 50,
            accessor: ({id, active}) => ({id, active}),
            Cell: ({value: {id, active}}) => ( // eslint-disable-line react/prop-types
                <section>
                    <Toggle
                        value={`toggle_${id}`}
                        checked={active === true}
                        onChange={async (event) => {
                            if (event.target.checked) {
                                await users.setUserState(id, true);
                                const user = users.getUser(id);
                                await sendmail(user.firstName, user.email);
                            } else {
                                await users.setUserState(id, false);
                            }
                        }}
                    />
                </section>
            ),
        }];
    useEffect(() => {
        users.loadUsers();
    }, []);

    return (
        <Fragment>
            <Body>
                <Nav/>
                <Content>
                    <H1>{i18n.t('users.users')}</H1>
                    <Table
                        columns={columns}
                        data={users.usersMap}
                    />
                </Content>
                <Popup
                    name="AddUser"
                    isVisible={popupState === 'AddUser'}
                    chosenUser={chosenUser}
                    onClose={() => setPopupState(false)}
                    popupName={popupState}
                    initialValues={{active: true}}
                />
                <Popup
                    name="EditUser"
                    editMode
                    isVisible={popupState === 'EditUser'}
                    chosenUser={chosenUser}
                    onClose={() => setPopupState(false)}
                    popupName={popupState}
                    initialValues={chosenUser}
                />
            </Body>
        </Fragment>
    );
};
