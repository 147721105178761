import React from "react";
import {
    NumberInput,
    SettingsPanel,
    SettingsPanelHeader,
    SettingsPanelItem,
    FieldLabelText,
    ApplyButton,
    SettingsContainer,
    SaveSettingsContainer
} from "./styled";
import PropTypes from 'prop-types';
import { Field, FieldArray, Form } from 'formik';
import i18n from '../../i18n';
import Toggle from '../../Common/Toggle';
import InputWithLabel from "../../Common/InputWithLabel";

const SettingsForm = ({
                        values,
                        errors,
                        setFieldValue,
                        displayLoader
                      }) => {
    return(
        <Form name="modStopForm">
            <SettingsContainer>
                <SettingsPanel>
                    <SettingsPanelHeader>
                        Mod Stop #{values.id}
                    </SettingsPanelHeader>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="id"
                            label={i18n.t('modStops.idTitle') + '*'}
                            type="text"
                            errorMessage={errors.id}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>

                    <SettingsPanelItem type='text'>
                        <Field
                            name="name"
                            label={i18n.t('modStops.nameTitle') + '*'}
                            type="text"
                            errorMessage={errors.name}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>

                    <SettingsPanelItem type='text'>
                        <Field
                            name="latitude"
                            label={i18n.t('modStops.latitudeTitle') + '*'}
                            type="text"
                            errorMessage={errors.latitude}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="longitude"
                            label={i18n.t('modStops.longitudeTitle') + '*'}
                            type="text"
                            errorMessage={errors.longitude}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="type"
                            label={i18n.t('modStops.typeTitle')}
                            type="text"
                            errorMessage={errors.type}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="area"
                            label={i18n.t('modStops.areaTitle')}
                            type="text"
                            errorMessage={errors.area}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="address"
                            label={i18n.t('modStops.addressTitle') + '*'}
                            type="text"
                            errorMessage={errors.address}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="imageUrl"
                            label={i18n.t('modStops.imageUrlTitle')}
                            type="text"
                            errorMessage={errors.imageUrl}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="phase"
                            label={i18n.t('modStops.phaseTitle') + '*'}
                            type="text"
                            errorMessage={errors.phase}
                            component={InputWithLabel}
                        />
                    </SettingsPanelItem>
                </SettingsPanel>
               </SettingsContainer>
            <SaveSettingsContainer>
                <ApplyButton
                    redButtons={false}
                    disabled={false}
                    type="submit"
                    data-test-id="submitSettings"
                    title={i18n.t('settings.labels.saveButton')}
                    displayLoader={displayLoader}
                />
            </SaveSettingsContainer>
        </Form>
)};

SettingsForm.propTypes = {
    values: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    displayLoader: PropTypes.bool.isRequired,
};

export default SettingsForm;
