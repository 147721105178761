import networkService from '../../Services/network';

export const getStops = async () => {
  const { data: stops } = await networkService.get('/api/v1/admin/stops');
  return stops;
};

export const createMoDStop = async (payload) => {
  await networkService.post('/api/v1/admin/stops', payload);
};

export const saveMoDStop = async (id, payload) => {
  await networkService.put(`/api/v1/admin/stops/${id}`, payload);
};

export const removeMoDStop = async (id) => {
  await networkService.delete(`/api/v1/admin/stops/${id}`);
};

export const queueCalc = async () => {
  await networkService.post(`/api/v1/admin/stops/calc`);
};

export const getSelectedStop = async (id) => {
  const { data: stop } = await networkService.get(`/api/v1/admin/stops/${id}`);
  return stop;
};

export const uploadCSV = async (formdata, ignoreOrMerge) => {
  await networkService.post(`/api/v1/admin/stops/csv?ignoreOrMerge=${ignoreOrMerge}`, formdata, {
    headers:{
      'content-type': 'multipart/form-data',
     }
  });
}
