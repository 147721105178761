import { Redirect } from "react-router-dom";
import React, { Fragment, useState } from "react";
import i18n from "../../i18n";
import Nav from "../Nav";
import { Body, Content, } from "./styled";
import { H1 } from "../../Common/Header";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Formik, Form, Field,ErrorMessage } from "formik";
import {
    Link
} from "react-router-dom";

import stopsContainer from '../../contexts/stopsContainer';


const FieldIsRequiredMsg = i18n.t('settings.validation.fieldIsRequired');

export default () => {
    const stops = stopsContainer.useContainer();
    const [displayLoader, setDisplayLoader] = useState(false);
    const history = useHistory();

    if (!localStorage.token) {
        return <Redirect to="/login" />;
    }


    return (
        <Fragment>
            <Body>
                <Nav />
                <Content>
                    <H1>
                        Upload CSV
                    </H1>

                    <div class="row">
                        <div class="col-1">
                            <Link to="/stops" className="btn btn-primary">Back</Link>
                            
                        </div>
                        <div class="col-11">
                                <Formik
                                    validateOnBlur={false}
                                    validateOnChange={false}
                                    initialValues={{
                                        ignoreOrMerge: "ignore"
                                    }}
                                    validationSchema={Yup.object().shape({
                                        file: Yup.mixed().required(),
                                        ignoreOrMerge: Yup.string().required(),
                                    })}
                                    onSubmit={async (values, actions) => {
                                        actions.setSubmitting(true);
                                        setTimeout(() => setDisplayLoader(true), 100);

                                        try {
                                            const formdata = new FormData();
                                            formdata.append("file", values.file);
                                            
                                            await stops.uploadStopsCSV(formdata, values.ignoreOrMerge);
                                            alert("upload complete");
                                        } catch (error) {
                                            console.log(error);
                                            alert(error);
                                        }
                                        actions.setSubmitting(false);
                                        setTimeout(() => setDisplayLoader(false), 1200);
                                    }}
                                    render={({isSubmitting, values, setFieldValue}) => (
                                        <Form>          
          <div className="form-group" id="my-radio-group"><b>Ignore or Merge existing MoD Stops</b></div>
          <div className="form-check" role="group" aria-labelledby="my-radio-group">
            <label>
              <Field className="form-check-input" type="radio" name="ignoreOrMerge" value="ignore" />
              Ignore existing MoD Stops
            </label>
            <br />
            <label>
              <Field className="form-check-input" type="radio" name="ignoreOrMerge" value="merge" />
              Merge with existing MoD Stops
            </label>
            <br />
          </div>

          <div style={{padding: '10px'}}>
            <p>Sample CSV (first line must contain at least these fields)</p>
            <img src="/csv-sample.png" />
          </div>
          <div className="form-group">
                  <label for="file"><b>CSV File</b></label>
                  <input id="file" name="file" type="file" onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                  }} className="form-control" />
                </div>
           <button className="btn btn-primary" type="submit" disabled={isSubmitting}>
             Submit
           </button>
         </Form>
                                    )}
                                />
                        </div>

                    </div>


                </Content>
            </Body>
        </Fragment>
    );
};
