import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from 'react-router-dom';
import Home from './Home';
import Settings from './Settings';
import PaymentSettings from './PaymentSettings';
import LegalSettings from './LegalSettings';
import Login from './Login';
import ServiceHours from './ServiceHours';
import MoDStops from './MoDStops';
import MoDStopsEdit from './MoDStops/add-edit';
import ModStopsUpload from './MoDStops/upload';

export default () => (
    <Router>
        <Switch>
            <Route exact path="/">
                <Home/>
            </Route>
            <Route exact path="/settings">
                <Settings/>
            </Route>
            <Route exact path="/service-hours">
                <ServiceHours/>
            </Route>
            <Route exact path="/payment-settings">
                <PaymentSettings/>
            </Route>
            <Route exact path="/legal">
                <LegalSettings/>
            </Route>
            <Route exact path="/stops">
                <MoDStops/>
            </Route>
            <Route exact path="/stops/upload">
                <ModStopsUpload/>
            </Route>
            <Route exact path="/stops/:id">
                <MoDStopsEdit/>
            </Route>
            <Route exact path="/login">
                <Login/>
            </Route>
        </Switch>
    </Router>
);
