import React, { Fragment, useEffect, useState } from 'react';
import { Redirect, Link } from 'react-router-dom';
import i18n from '../../i18n';
import Nav from '../Nav';
import { H1 } from '../../Common/Header';
import { Body, Content, ApplyButton } from './styled';
import SvgIcon from '../../Common/SvgIcon'
import { ReactComponent as editIcon } from '../../assets/edit.svg'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet';

import stopsContainer from '../../contexts/stopsContainer';

export default () => {
  if (!localStorage.token) {
    return <Redirect to="/login" />;
  }

  const stops = stopsContainer.useContainer();

  const [filteredStops, setFilteredStops] = useState([]);
  const [filteredTerm, setFilteredTerm] = useState("");

  useEffect(() => {
    stops.loadStops()
  }, []);


  const queueRecalculateDistancesTask = async () => {
    await stops.queueCalcMatrix();
    alert("Task queued.")
  };

  const handleFiltering = (event) => {
    event.preventDefault();

    if (!event.target.value || event.target.value === "") {
      setFilteredStops(stops.stops);
      setFilteredTerm("");
    } else {
      setFilteredStops(stops.stops.filter(s => `${s.id}${s.name}${s.address}`.toLowerCase().includes(event.target.value.toLowerCase())));
      setFilteredTerm(event.target.value);
    }

  };

  const handleCSVMatrixDownload = () => {
    const filter = filteredTerm;
    fetch(encodeURI('/api/v1/admin/stops/distance-matrix-csv?filter=' + filter), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/pdf',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    })
    .then((response) => response.blob())
    .then((blob) => {
      // Create blob link to download
      const url = window.URL.createObjectURL(
        new Blob([blob]),
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `price-matrix.csv`,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    });
  };

  const handleCSVStopDownload = () => {
    const filter = filteredTerm;

    fetch(encodeURI('/api/v1/admin/stops/csv?filter=' + filter), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/pdf',
      'Authorization': `Bearer ${localStorage.getItem('token')}`
    },
  })
  .then((response) => response.blob())
  .then((blob) => {
    // Create blob link to download
    const url = window.URL.createObjectURL(
      new Blob([blob]),
    );
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute(
      'download',
      `stops.csv`,
    );

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
  });
  };


  const position = [49.347598, 8.148418];

  return (
    <Fragment>
      <Body>
        <Nav />
        <Content>
          <H1>
            {i18n.t('modStops.title')}
          </H1>
          <div class="input-group mb-3">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">🔍</span>
            </div>
            <input type="text" class="form-control" placeholder="Filter" onInput={(event) => handleFiltering(event)} aria-label="Username" aria-describedby="basic-addon1" />
          </div>
          <Link to='/stops/new' class="btn btn-primary" >Add MoD Stop</Link>
          <button class="btn btn-primary" style={{ marginLeft: '10px' }} onClick={() => handleCSVMatrixDownload()}>Export Price Matrix as CSV</button>
          <button class="btn btn-primary" style={{ marginLeft: '10px' }} onClick={() => handleCSVStopDownload()}>Export Selection as CSV</button>
          <Link to='/stops/upload' class="btn btn-primary" style={{ marginLeft: '10px' }}> Upload CSV</Link>
          <button class="btn btn-primary" onClick={async () => {
            await queueRecalculateDistancesTask();
          }} style={{ marginLeft: '10px' }} >Recalculate Distance Matrix</button>

          <Map center={position} zoom={12} style={{ width: '100%', height: '480px', marginTop: '25px', marginBottom: '25px' }}>
            <TileLayer
              attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {filteredTerm === "" ? stops.stops.map(s => {
              return <Marker position={[s.latitude, s.longitude]} key={s.id}>
                <Popup>
                  {s.name}<br />
                  <Link to={`/stops/${s.id}`} style={{ backgroundColor: '#23a0fe', color: 'white', padding: '5px', borderRadius: '4px' }}>Edit</Link>
                </Popup>
              </Marker>
            }) :
              filteredStops.map(s => {
                return <Marker position={[s.latitude, s.longitude]} key={s.id}>
                  <Popup>
                    {s.name}<br />
                    <Link to={`/stops/${s.id}`} style={{ backgroundColor: '#23a0fe', color: 'white', padding: '5px', borderRadius: '4px' }}>Edit</Link>
                  </Popup>
                </Marker>
              })

            }

          </Map>


          <table style={{ width: '100%' }} className="table">
            <tr>
              <th scope="col">MoD Id</th>
              <th scope="col">Name</th>
              <th scope="col">Latitude</th>
              <th scope="col">Longitude</th>
              <th scope="col">Address</th>
              <th scope="col">Phase</th>
              <th scope="col">Actions</th>
            </tr>
            <tbody>
              {filteredTerm === "" ? stops.stops.map(s => {
                return <tr key={s.id}>
                  <td scope="row">{s.id}</td>
                  <td>{s.name}</td>
                  <td>{s.latitude}</td>
                  <td>{s.longitude}</td>
                  <td>{s.address}</td>
                  <td>{s.phase}</td>
                  <td><Link to={`/stops/${s.id}`} style={{ backgroundColor: '#23a0fe', color: 'white', padding: '5px', borderRadius: '4px' }}>Edit</Link></td>
                </tr>
              }) :
                filteredStops.map(s => {
                  return <tr key={s.id}>
                    <td scope="row">{s.id}</td>
                    <td>{s.name}</td>
                    <td>{s.latitude}</td>
                    <td>{s.longitude}</td>
                    <td>{s.address}</td>
                    <td>{s.phase}</td>
                    <td><Link to={`/stops/${s.id}`} style={{ backgroundColor: '#23a0fe', color: 'white', padding: '5px', borderRadius: '4px' }}>Edit</Link></td>
                  </tr>
                })

              }
            </tbody>
          </table>
        </Content>

      </Body>


    </Fragment>
  );
};
