import { useState } from 'react';
import { createContainer } from 'unstated-next';
import { getStops, createMoDStop, saveMoDStop, queueCalc, removeMoDStop, getSelectedStop, uploadCSV } from './api';

const useStops = () => {
  const [stops, setStops] = useState([]);
  const [selectedStop, setSelectedStop] = useState({});
  
  const loadStops = async () => {
    const stopsData = await getStops();
    setStops(stopsData);
  }; 

  const loadSelectedStop = async (id) => {
    if (id === 'new') {
      return;
    }
    const stopsData = await getSelectedStop(id);
    setSelectedStop(stopsData);
  }; 

  const createStop = async (payload) => {
    await createMoDStop(payload);
  }

  const saveStop = async (id, payload) => {
    await saveMoDStop(id, payload);
  }

  const removeStop = async (id) => {
    await removeMoDStop(id);
  }

  const queueCalcMatrix = async () => {
    await queueCalc();
  }

  const uploadStopsCSV = async (formdata, ignoreOrMerge) => {
    await uploadCSV(formdata, ignoreOrMerge);
  }

  return {
    loadStops,
    createStop,
    saveStop,
    removeStop,
    queueCalcMatrix,
    loadSelectedStop,
    uploadStopsCSV,
    stops,
    selectedStop
  };
};

export default createContainer(useStops);

