import React from "react";
import {
    NumberInput,
    SettingsPanel,
    SettingsPanelColumn,
    SettingsPanelItem,
    ApplyButton,
    SettingsContainer,
    SaveSettingsContainer,
} from "./styled";
import PropTypes from 'prop-types';
import {Field, FieldArray, Form} from 'formik';
import i18n from '../../i18n';
import Toggle from '../../Common/Toggle';
import InputWithLabel from "../../Common/InputWithLabel";
import SettingsForm from "../Settings/form";

const PaymentSettingsForm = ({
                                 values,
                                 errors,
                                 setFieldValue,
                                 displayLoader
                             }) => {
    return (
        <Form name="paymentSettingsForm">
            <SettingsContainer>
                <SettingsPanel>
                    <SettingsPanelColumn>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="GRUNDPREIS"
                                label={i18n.t('paymentSettings.grundpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.GRUNDPREIS}
                            />
                        </SettingsPanelItem>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="KILOMETERPREIS"
                                label={i18n.t('paymentSettings.kilometerpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.KILOMETERPREIS}
                            />
                        </SettingsPanelItem>
                    </SettingsPanelColumn>
                    <SettingsPanelColumn>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="VRNGRUNDPREIS"
                                label={i18n.t('paymentSettings.vrngrundpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.VRNGRUNDPREIS}
                            />
                        </SettingsPanelItem>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="LUFTLINIENPREIS"
                                label={i18n.t('paymentSettings.luftlinienpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.LUFTLINIENPREIS}
                            />
                        </SettingsPanelItem>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="BAHNCARDVRNGRUNDPREIS"
                                label={i18n.t('paymentSettings.bahncardvrngrundpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.BAHNCARDVRNGRUNDPREIS}
                            />
                        </SettingsPanelItem>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="BAHNCARDLUFTLINIENPREIS"
                                label={i18n.t('paymentSettings.bahncardluftlinienpreis')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.BAHNCARDLUFTLINIENPREIS}
                            />
                        </SettingsPanelItem>
                        <SettingsPanelItem type='text'>
                            <Field
                                name="CREDIT_RECHARGE_VALUES"
                                label={i18n.t('paymentSettings.aufladebetraege')}
                                type="text"
                                component={InputWithLabel}
                                errorMessage={errors.CREDIT_RECHARGE_VALUES}
                            />
                        </SettingsPanelItem>
                    </SettingsPanelColumn>
                    <SettingsPanelItem type='text'>
                        <Field
                            name="INITIAL_BALANCE"
                            label={i18n.t('paymentSettings.initalguthaben')}
                            type="text"
                            component={InputWithLabel}
                            errorMessage={errors.INITIAL_BALANCE}
                        />
                    </SettingsPanelItem>
                </SettingsPanel>

            </SettingsContainer>
            <SaveSettingsContainer>
                <ApplyButton
                    redButtons={false}
                    disabled={false}
                    type="submit"
                    data-test-id="submitSettings"
                    title={i18n.t('settings.labels.saveButton')}
                    displayLoader={displayLoader}
                />
            </SaveSettingsContainer>
        </Form>
    );
};

PaymentSettingsForm.propTypes = {
    values: PropTypes.shape({}).isRequired,
    errors: PropTypes.shape({}).isRequired,
    setFieldValue: PropTypes.func.isRequired,
    displayLoader: PropTypes.bool.isRequired,
};

export default PaymentSettingsForm;
