import { Redirect } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";
import i18n from "../../i18n";
import Nav from "../Nav";
import { Body, Content } from "./styled";
import { H1 } from "../../Common/Header";
import PaymentSettingsForm from "./form";
import settingsContainer from "../../contexts/settingsContainer";
import * as Yup from "yup";
import { Formik } from "formik";
import diff from "object-diff";

const FieldIsRequiredMsg = i18n.t('settings.validation.fieldIsRequired');

const PaymentSettingsView = () => {
    const settings = settingsContainer.useContainer();
    const [showForm, setShowForm] = useState(false);
    const [displayLoader, setDisplayLoader] = useState(false);

    useEffect(() => {
        settings.loadSettings();
    }, []);

    useEffect(() => {
        if (settings.settingsList) {
            console.log('LIST', settings.settingsList);
            setShowForm(true);
        }
    }, [settings.settingsList]);

    if (!localStorage.token) {
        return <Redirect to="/login" />;
    }

    return (
        <Fragment>
            <Body>
                <Nav />
                <Content>
                    <H1>
                        {i18n.t('paymentSettings.title')}
                    </H1> {showForm &&
                        <Formik
                            validateOnBlur={false}
                            validateOnChange={false}
                            {...{ initialValues: settings.settingsList }}
                            validationSchema={Yup.object().shape({
                                GRUNDPREIS: Yup.number().required(FieldIsRequiredMsg),
                                KILOMETERPREIS: Yup.number().required(FieldIsRequiredMsg),
                                VRNGRUNDPREIS: Yup.number().required(FieldIsRequiredMsg),
                                LUFTLINIENPREIS: Yup.number().required(FieldIsRequiredMsg),
                                CREDIT_RECHARGE_VALUES: Yup.string().required(FieldIsRequiredMsg),
                                INITIAL_BALANCE: Yup.number().required(FieldIsRequiredMsg),
                                BAHNCARDVRNGRUNDPREIS: Yup.number().required(FieldIsRequiredMsg),
                                BAHNCARDLUFTLINIENPREIS: Yup.number().required(FieldIsRequiredMsg),
                            })}
                            onSubmit={async (values, actions) => {
                                actions.setSubmitting(true);
                                setTimeout(() => setDisplayLoader(true), 100);

                                const { ...otherValues } = diff(settings.settingsList, values);
                                try {
                                    Object.keys(otherValues).map(async (key, index) => {
                                        await settings.UpdateSetting(key, otherValues[key]);
                                    });
                                } catch (error) {
                                    console.log(error);
                                }
                                actions.setSubmitting(false);
                                setTimeout(() => setDisplayLoader(false), 1200);
                            }}
                            render={props => (
                                <PaymentSettingsForm {...props} displayLoader={displayLoader} />
                            )}
                        />}
                </Content>
            </Body>
        </Fragment>
    );
};
export default PaymentSettingsView;



