import {Redirect} from 'react-router-dom';
import React, {
    Fragment,
    useEffect,
    useRef,
    useState
} from 'react';
import diff from "object-diff";
import {
    Editor,
    EditorState,
    convertToRaw,
    RichUtils
} from "draft-js";
import i18n from '../../i18n';
import settingsContainer from '../../contexts/settingsContainer';
import Nav from '../Nav';
import {
    Body,
    Content,
    SettingsContainer,
    SettingsPanel,
    SettingsPanelItem,
    EditorContainer
} from './styled';
import {H1} from '../../Common/Header';

import {ApplyButton, SaveSettingsContainer} from "../Settings/styled";

export default () => {
    const settings = settingsContainer.useContainer();

    if (!localStorage.token) {
        return <Redirect to="/login"/>;
    }

    useEffect(() => {
        settings.loadSettings();
    }, []);

    const [editorContent, setEditorContent] = useState('');

    useEffect(() => {
        if (settings.settingsList) {
            setEditorContent(settings.settingsList.DATENSCHUTZ)
        }
    }, [settings.settingsList]);

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    async function saveContent (editorState) {
        const contentState = editorState.getCurrentContent();
        const value = convertToRaw(contentState);
        console.log('value', value);
        setEditorContent(contentState);
        try {
            console.log('value', value.blocks[0].text);
            await settings.UpdateSetting('DATENSCHUTZ', value.blocks[0].text);
        }  catch (error) {
            console.log(error);
        }
    }

    function EditorDataPrivacy() {
        const editor = useRef(null);
        function focusEditor() {
            editor.current.focus();
        }
        const onChange = (editorState) => {
            setEditorState(editorState);
        }
        const handleKeyCommand = (command, editorState) => {
            const newState = RichUtils.handleKeyCommand(editorState, command);

            if (newState) {
                onChange(newState);
                return 'handled';
            }
            return 'not-handled';
        }
        return (
            <EditorContainer onClick={focusEditor}>

                <Editor
                    ref={editor}
                    editorState={editorState}
                    handleKeyCommand={handleKeyCommand}
                    onChange={onChange}
                    placeholder={editorContent}
                />
            </EditorContainer>
        );
    }

    return (
        <Fragment>
            <Body>
                <Nav/>
                <Content>
                    <H1>
                        {i18n.t('legalSettings.headline')}
                    </H1>
                    <SettingsContainer>
                        <SettingsPanel>
                            <SettingsPanelItem>
                                <EditorDataPrivacy/>
                            </SettingsPanelItem>
                        </SettingsPanel>
                    </SettingsContainer>
                    <SaveSettingsContainer>
                        <ApplyButton
                            title={i18n.t('settings.labels.saveButton')}
                            onClick={() => saveContent(editorState)}
                        />
                    </SaveSettingsContainer>
                </Content>
            </Body>
        </Fragment>
    );
};
